<template>
  <div class='px-2 lg:pl-8'>
    <portal to='headerSpace'>
      <div class='flex flex-row'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide'>사용자관리</h1>
      </div>
    </portal>
    <table class='text-sm table-fixed'>
      <thead>
        <tr class='border-t border-b'>
          <th class='text-left border-r p-2' rowspan='2'>name</th>
          <th class='text-left border-r p-2' rowspan='2'>email</th>
          <th class='text-left border-r p-2' rowspan='2'>sms #</th>
          <th class='text-left border-r p-2' rowspan='2'>roles</th>
          <th class='text-left border-r p-2' rowspan='2'>group</th>
          <th class='text-center border-r p-2' colspan='4'>상세정보</th>
          <th class='text-left p-2' v-if='isAdmin' rowspan='2'>수정 / 퇴사</th>
        </tr>
        <tr class='border-b'>
          <th class='text-left border-r p-2'>조회등급</th>
          <th class='text-left border-r p-2'>그룹</th>
          <th class='text-left border-r p-2'>팀</th>
          <th class='text-left border-r p-2'>직급</th>
        </tr>
      </thead>
      <tbody>
        <tr class='border-b' v-for='user in users' :key='`user-${user.id}`'>
          <td class='text-left border-r p-2 whitespace-no-wrap'>{{ user.name }}</td>
          <td class='text-left border-r p-2 whitespace-no-wrap'>{{ user.email }}</td>
          <td class='text-left border-r p-2 whitespace-no-wrap'>{{ user.sms_number }}</td>
          <td class='text-left border-r p-2'>
            <el-tag v-for='role in user.roles' :key='`userrole-${user.id}-${role}`' class='mr-2'>{{ role }}</el-tag>
          </td>
          <td class='text-left border-r p-2'>
            <el-tag v-for='group in user.user_groups' :key='`usergroup-${user.id}-${group.group_name}`' class='mr-2'>{{ group.group_name }}</el-tag>
          </td>
          <td class='text-left border-r p-2'>{{ user.organogram.layer }}</td>
          <td class='text-left border-r p-2'>{{ user.organogram.department }}</td>
          <td class='text-left border-r p-2'>{{ user.organogram.team }}</td>
          <td class='text-left border-r p-2'>{{ user.organogram.title }}</td>
          <td class='text-left p-2' v-if='isAdmin'>
            <button class='border rounded-md p-2 lg:p-3 mr-5'
              @click='userDetailsOpen(user)'>
              수정
            </button>
            <button class='hover:bg-red-200 border rounded-md p-2 lg:p-3'
              @click.stop='showConfirm(user.id)'>
              퇴사
            </button>
            <el-popover
              placement='bottom'
              class='w-full'
              v-if='showDeletePopover(user.id)'
              v-model='showConfirmDelete'>
              <div>
                <span class='mb-8 mr-2'>
                  <span class='font-semibold'>
                    {{userToDelete.email}}
                  </span> 퇴사 처리하시겠습니까?
                </span>
                <button @click.stop='hideConfirm' class='p-3 rounded-md text-sm border border-gray-400 hover:border-gray-800'>취소</button>
                <button @click.stop='removeUser(user.id)' class='p-3 text-red-700 text-sm border border-transparent hover:border-red-400 rounded-md'>저장</button>
              </div>
            </el-popover>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'UserAccounts',
  data () {
    return {
      deleteUserId: 0,
      showConfirmDelete: false,
    }
  },
  computed: {
    ...mapState('users', [
      'currentUserInfo',
      'users',
      'userGroups'
    ]),
    userToDelete () {
      return this.users.find(user => user.id === this.deleteUserId)
    },
    isAdmin () {
      return this.currentUserInfo.roles.includes('admin')
    },
  },
  methods: {
    ...mapActions('users', [
      'getUsers',
      'getUserGroups',
      'deleteUser',
      'userDetailsOpen',
    ]),
    hideConfirm () {
      this.showConfirmDelete = false
      this.deleteUserId = 0
    },
    removeUser (userID) {
      this.deleteUser(userID)
    },
    showConfirm (userId) {
      this.deleteUserId = userId
      this.showConfirmDelete = true
    },
    showDeletePopover (userId) {
      return this.showConfirmDelete && this.deleteUserId === userId
    },
  },
  mounted () {
    this.getUsers()
    this.getUserGroups()
  }
}
</script>
