<template>
  <div class='pb-8 px-2 lg:px-8'>
    <portal to='headerSpace'>
      <div class='flex flex-row'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide'>Group권한관리</h1>
      </div>
    </portal>
    <div class='flex flex-row my-6'>
      <button
        class='border p-3 rounded-md border-gray-200 text-xs text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md flex flex-row justify-center items-center'
        @click='addNewGroup()'>
        <plus-circle-icon />
        Add
      </button>
    </div>
    <label class='p-2'>{{ userGroupInvestmentDescription }}</label>
    <table class='text-sm table-fixed'>
      <thead>
        <tr>
          <th class='text-left border-r p-2 uppercase'>group no</th>
          <th class='text-left border-r p-2 uppercase'>group name</th>
          <th class='text-left border-r p-2 uppercase'>users</th>
          <th class='text-left border-r p-2 uppercase'>dashboards</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for='group in userGroups' :key='`user-group-${group.id}`'>
          <td class='text-left border-r p-2'>{{ group.group_no }}</td>
          <td class='text-left border-r p-2'>{{ group.group_name }}</td>
          <td class='text-left border-r p-2'>
            <el-tag v-for='user in filteredUsersByIds(group.user_ids)' :key='`group-users-${group.id}-${user.id}`' class='mr-2'>{{ user.name }} ({{user.email}})</el-tag>
          </td>
          <td class='text-left border-r p-2'>
            <el-tag v-for='dashboardName in dashboardNames(group.dashboard_view_ids)' :key='`group-dashboards-${group.id}-${dashboardName}`' class='mr-2'>{{ dashboardName }}</el-tag>
          </td>
          <td>
            <button
              class='border p-3 rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
              @click='editGroup(group)'>
              <pencil-alt-icon class='inline-block' />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState }      from 'vuex'
import { PencilAltIcon, PlusCircleIcon } from '@vue-hero-icons/outline'

export default {
  name: 'UserGroups',
  components: { PencilAltIcon, PlusCircleIcon },
  computed: {
    ...mapState('companySettings', [
      'companySettings',
    ]),
    ...mapGetters('dashboardViews', [
      'sharedDashboardViews',
    ]),
    ...mapGetters('users', [
      'filteredUsersByIds',
    ]),
    ...mapState('users', [
      'users',
      'userGroups'
    ]),
    userGroupInvestmentDescription () {
      if (this.companySettings.apply_user_group_to_investments) {
        return 'Card 를 생성하는 Group 만 볼 수 있습니다.'
      } else {
        return ''
      }
    },
  },
  methods: {
    ...mapActions('dashboardViews', [
      'getDashboardViews',
     ]),
    ...mapActions('users', [
      'getUsers',
      'getUserGroups',
      'userGroupDetailsOpen',
    ]),
    addNewGroup () {
      this.userGroupDetailsOpen({
        id: 0,
        group_no: '',
        group_name: '',
        user_ids: [],
        user_names: '',
        dashboard_view_ids: []})
    },
    dashboardNames (dashboardIds) {
      return this.sharedDashboardViews.filter(dashboard => dashboardIds.includes(dashboard.id)).map(dashboard => dashboard.dashboard_view_name)
    },
    editGroup (group) {
      this.userGroupDetailsOpen(group)
    },
  },
  mounted () {
    Promise.all([this.getDashboardViews(), this.getUsers()]).then(() => this.getUserGroups())
  }
}
</script>
