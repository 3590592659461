<template>
  <div>
    <div class='tabs-container lg:pl-8 pt-4 pb-2 border-b border-gray-100 text-gray-600'>
      <label
        :class='selectedStyle("user-accounts")'
        class='mr-8 px-4 pb-2 pt-3 rounded-t cursor-pointer whitespace-no-wrap'>
        <input value='user-accounts' v-model='view' type='radio' name='view' class='hidden'/>
        사용자관리
      </label>
      <label
        :class='selectedStyle("user-groups")'
        class='mr-8 px-4 pb-2 pt-3 rounded-t cursor-pointer whitespace-no-wrap'>
        <input value='user-groups' v-model='view' type='radio' name='view' class='hidden'/>
        Group권한관리
      </label>
    </div>
    <component :is='view' />
  </div>
</template>

<script>
import UserAccounts      from '@/views/manage_access/UserAccounts.vue'
import UserGroups        from '@/views/manage_access/UserGroups.vue'

export default {
  name: 'ManageAccess',
  components: {
    UserAccounts,
    UserGroups,
  },
  data () {
    return {
      view: 'user-accounts',
    }
  },
  methods: {
    selectedStyle (tab) {
      return (tab === this.view) ? 'text-gray-800 font-semibold border-b-2 border-gray-800' : 'border-b-2 border-transparent'
    }
  }
}
</script>
